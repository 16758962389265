import { Grid, Box, Typography, Button, Fab } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useWindowScrollPositions } from '../hooks/getScrollPositions'
import { useNavigate } from 'react-router-dom'
import Card from '../utils/CustomCards'
import { useParams } from 'react-router-dom'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined'
import SecurityIcon from '@mui/icons-material/Security'
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined'
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined'
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined'
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import useWindowDimensions from '../hooks/getScreenDimensions'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import Title from '../utils/Title'
import director_img from './Director.jpg'

export default function Main() {
  const { id } = useParams()
  useEffect(() => {
    console.log(useParams)
  }, [])
  const { width } = useWindowDimensions()
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const { scrollY } = useWindowScrollPositions()
  const navigate = useNavigate()
  const moveToWeatherAdbisoryPage = () => {
    navigate('/weather-advisory')
  }
  return (
    <Box>
      <Title title={'Home'}></Title>
      <Grid container mt="50px">
        {width >= 1200 ? <Grid lg={1} xl={2}></Grid> : null}
        <Grid
          item
          pl={2}
          pr={2}
          xs={12}
          lg={6}
          xl={width < 1800 ? 4 : 3}
          alignItems="flex-end"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            mainCardImage={director_img}
            mainCardImageHeight={width < 1200 ? '250px' : '400px'}
            mainCardMaxWidth={'1000px'}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 1200 ? '250px' : '400px'}
            mainCardMaxHeight={'410px'}
            mainCardBorderRadius={'15px'}
            mainCardWriteOnImage={null}
            basicCard={false}
          />
        </Grid>
        {width < 1200 && width > 800 ? <Grid xs={1}></Grid> : null}
        <Grid
          item
          xs={width <= 800 ? 12 : 9}
          lg={5}
          xl={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginTop: width < 1200 ? '50px' : '0px',
          }}
        >
          <Typography align="start" fontFamily="Poppins" color="rgb(101,77,52)"></Typography>
          <Typography align="start" fontSize={30} mb={2} fontFamily="Poppins" color="black">
            <h4>Director Overseas Employment</h4>
          </Typography>
          <Typography
            align="start"
            fontFamily="Poppins"
            mb={3}
            line-height="10px"
            word-spacing="15px"
            textAlign={'justify'}
            style={{ wordSpacing: '2px' }}
          >
            Mr. Mohsin Jamil has huge and vast experience in the corporate world. He entered into
            the business sector in 2016. Besides being the Vice Chairman of an International
            Airlines, he has held important positions like Deputy MD in Al Ghurair Giga & Deputy MD
            in Gerrys Group International. He holds a Master degree in Public Administration and
            degree of Law and is member of Pakistan Bar Council. He is an entrepreneur having a
            group of companies in the field of Law, Aesthetics and Travel. Pakistan has a potential
            market all around the globe, where we can provide the finest human resource, duly
            trained and well equipped to perform and contribute in the international industry. We
            are certain that with our dedication and commitment, we will be able to make requisite
            presence in the International Market in the World.
          </Typography>
        </Grid>
        {width < 1200 && width > 800 ? <Grid xs={1}></Grid> : null}
        {width >= 1200 ? <Grid lg={1} xl={2}></Grid> : null}
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}></Grid>
      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}>
        <Grid item xs={12} md={width < 1800 ? 10 : 7}>
          <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              borderBottom: '1px solid rgb(101,77,52)',
              marginBottom: '5px',
              paddingBottom: '5px',
            }}
          >
            <h4>Director's Message</h4>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography
            align="start"
            mt="20px"
            mb="15px"
            fontFamily="Poppins"
            textAlign={'justify'}
            style={{ wordSpacing: '10px' }}
          >
            World is a global village and interdependence is an inevitable virtue. No matter how
            much technology penetrates in the industrial sector. The human resource remains the most
            potent component of the work force. Pakistan is having a versatile & potent human
            resource potential for making the impossible possible. The security forces have various
            fields, in which the HR is selected through a deliberate mechanism. Their career
            development in their relevant field is a major ingredient of there grooming. Today the
            security forces have multifaced HR including Engineers, Pilot, Drivers, Technician,
            Security Experts, Hospitality Experts etc. These are available for rendering services to
            the well reputed companies around the world. We intend capitalizing the skill of this HR
            and after certification, enabling them to be part of any lead organization of the world.
            This shall seek huge remittances for our motherland, besides creating harmony with are
            friendly countries.
          </Typography>
        </Grid>
      </Grid>

      <Box
        role="presentation"
        sx={{
          display: 'block',
          position: 'fixed',
          bottom: 32,
          left: 22,
          zIndex: 1,
          transition: 'transform 0.3s ease-in-out',
          transform: 'scale(1)',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      ></Box>
    </Box>
  )
}
