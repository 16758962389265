import Header from '../utils/Header'
import Footer from '../utils/Footer'
import ImageWithOverlay from '../utils/ImageWithOverlay'
import image from '../../public/image/teamwork.jpg'
import Main from './Main'
export default function Teams() {
  return (
    <>
      <Header />
      <ImageWithOverlay image={image} height="400px" titlePartOne="Our" titlePartTwo="Team" />
      <Main />
      <Footer />
    </>
  )
}
