import { Grid, Box, Typography, Fab } from '@mui/material'
import Card from '../utils/CustomCards'
import useWindowDimensions from '../hooks/getScreenDimensions'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined'
import SecurityIcon from '@mui/icons-material/Security'
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined'
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined'
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined'
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import Title from '../utils/Title'
import WORK from '../../public/home/home_image_4.jpg'
// import home_image_1 from '../../public/home/1 ()-yousaf-pTrhfmj2jDA-unsplash.jpg'
import home_image_2 from '../../public/home/1 (3).jpg'
import home_image_3 from '../../public/home/1 (2).jpg'
import home_image_4 from '../../public/home/1 (1).jpg'
import { ModelTrainingOutlined } from '@mui/icons-material'
export default function Home() {
  const { width } = useWindowDimensions()
  return (
    <Box>
      <Title title={'Home'}></Title>
      <Grid container mt="50px">
        {width >= 1200 ? <Grid lg={1} xl={2}></Grid> : null}
        <Grid
          item
          pl={2}
          pr={2}
          xs={12}
          lg={5}
          xl={width < 1800 ? 4 : 3}
          alignItems="flex-end"
          style={{
            display: 'flex',
            justifyContent: width < 1200 ? 'center' : 'end',
            alignItems: 'center',
          }}
        >
          <Card
            mainCardImage={WORK}
            mainCardImageHeight={width < 1200 ? '250px' : '400px'}
            mainCardMaxWidth={'425px'}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 1200 ? '250px' : '400px'}
            mainCardMaxHeight={'400px'}
            mainCardBorderRadius={'25px'}
            mainCardWriteOnImage={null}
            basicCard={false}
          />
        </Grid>
        {width < 1200 && width > 800 ? <Grid xs={1}></Grid> : null}
        <Grid
          item
          xs={width <= 800 ? 12 : 9}
          lg={5}
          xl={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginTop: width < 1200 ? '50px' : '0px',
          }}
        >
          <Typography
            align="start"
            fontSize={30}
            mb={4}
            fontFamily="Poppins"
            color="black"
            style={{
              borderBottom: '1px solid rgb(101,77,52)',
              marginBottom: '5px',
              paddingBottom: '5px',
              width: '100%',
            }}
          >
            <strong>WHAT WE DO?</strong>
          </Typography>

          <Typography align="start" fontFamily="Poppins" mb={3} textAlign={'justify'}>
            Overseas Employment Pakistan (OSE-P) is committed to address Pakistan’s Unemployment
            Challenge through an integrated approach – by engaging the key stakeholder in
            training/development (T&D) and employment industry in Pakistan and across the globe. The
            structure of the OSE-P trusts a cohesive approach to maximize the potential of the
            candidates through T&D, and contribute in multiple industries. OSE-P has over 100
            specialized institutions across Pakistan committed to certify its trainees to serve to
            the best of their abilities, expertise and above all disciplinary manners. OSE-P seeks
            employment demands across the globe and provide robust solutions to their needs through
            certifications, complaints redress-al mechanisms and ensuring a long and sustainable
            relations with its partner organizations and clients across the globe.
          </Typography>
        </Grid>
        {width < 1200 && width > 800 ? <Grid xs={1}></Grid> : null}
        {width >= 1200 ? <Grid lg={1} xl={2}></Grid> : null}
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="50px">
        <Grid item m="15px">
          <Card
            mainCardImage={home_image_4}
            mainCardImageHeight={width < 768 ? 200 : 250}
            mainCardMaxWidth={300}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 768 ? 200 : 250}
            mainCardMaxHeight={width < 768 ? 200 : 250}
            mainCardBorderRadius={'25px'}
            basicCard={false}
          />
        </Grid>
        <Grid item m="15px">
          <Card
            mainCardImage={home_image_2}
            mainCardImageHeight={width < 768 ? 200 : 250}
            mainCardMaxWidth={300}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 768 ? 200 : 250}
            mainCardMaxHeight={width < 768 ? 200 : 250}
            mainCardBorderRadius={'25px'}
            mainCardWriteOnImage={null}
            basicCard={false}
          />
        </Grid>

        {/* <Grid item m="15px">
          <Card
            mainCardImage={home_image_1}
            mainCardImageHeight={width < 768 ? 200 : 250}
            mainCardMaxWidth={300}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 768 ? 200 : 250}
            mainCardMaxHeight={width < 768 ? 200 : 250}
            mainCardBorderRadius={'25px'}
            basicCard={false}
          />
        </Grid> */}
        <Grid item m="15px">
          <Card
            mainCardImage={home_image_3}
            mainCardImageHeight={width < 768 ? 200 : 250}
            mainCardMaxWidth={300}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 768 ? 200 : 250}
            mainCardMaxHeight={width < 768 ? 200 : 250}
            mainCardBorderRadius={'25px'}
            basicCard={false}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}>
        <Grid item xs={12} md={width < 1800 ? 10 : 7}>
          <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              borderBottom: '1px solid rgb(101,77,52)',
              marginBottom: '5px',
              paddingBottom: '5px',
            }}
          >
            <strong>VALUES</strong>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={width < 1800 ? 10 : 7}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'start'}
          flexDirection={width < 786 ? 'column' : 'row'}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} mt="20px" mb="15px">
              <ModelTrainingOutlined style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Human Resource
              </Typography>
            </Box>
            <Box display={'flex'} mt="20px" mb="15px">
              <StarBorderOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Technical Skills
              </Typography>
            </Box>
            {/* <Box display={'flex'} mt="20px" mb="15px">
              <PrecisionManufacturingOutlinedIcon
                style={{ color: '#372d21', marginRight: '10px' }}
              />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Sourcing
              </Typography>
            </Box> */}
            {/* <Box display={'flex'} mt="20px" mb="15px">
              <ScienceOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Screening and short-Listing
              </Typography>
            </Box> */}
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} mt="20px" mb="15px">
              <DataSaverOnOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Recruitment
              </Typography>
            </Box>
            <Box display={'flex'} mt="20px" mb="15px">
              <CloudOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Sourcing
              </Typography>
            </Box>
            {/* <Box display={'flex'} mt="20px" mb="15px">
              <LandscapeOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Job Offers
              </Typography>
            </Box> */}
            {/* <Box display={'flex'} mt="20px" mb="15px">
              <AgricultureOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Education
              </Typography>
            </Box> */}
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} mt="20px" mb="15px">
              <HolidayVillageOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Training and Development
              </Typography>
            </Box>
            <Box display={'flex'} mt="20px" mb="15px">
              <RestaurantMenuOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Poverty Alleviation
              </Typography>
            </Box>
            {/* <Box display={'flex'} mt="20px" mb="15px">
              <ImportExportOutlinedIcon style={{ color: '#372d21', marginRight: '10px' }} />
              <Typography
                align="start"
                fontFamily="Poppins"
                color="text.secondary"
                textAlign={'justify'}
              >
                Exports Promotion
              </Typography>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}>
        <Grid item xs={12} md={width < 1800 ? 10 : 7}>
          <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              borderBottom: '1px solid rgb(101,77,52)',
              marginBottom: '5px',
              paddingBottom: '5px',
            }}
          >
            <strong>GOALS</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} md={width < 1800 ? 10 : 7}>
          <Typography align="start" mt="20px" mb="15px" fontFamily="Poppins" textAlign={'justify'}>
            <h3>Immediate Results</h3>
            <ul>
              <li>
                Coordination and Collaboration to identify the needs highlighted by the partners.
              </li>
              <li>Training & development and Capacity Building of the Human resource..</li>
            </ul>

            <br />

            <h3>Short Term Results</h3>
            <ul>
              <li>Addressing to the Needs of the clients, and shortlisting of the candidates.</li>
              <li>
                Making arrangements for the Human Resource for Traveling and addressing any legal
                frameworks.
              </li>
            </ul>
            <br />

            <h3>Medium Term Results</h3>
            <ul>
              <li>
                Ensuring a long-term relationship with the clients and dispatching ideal candidates
                for the clients.
              </li>
              <li>Maintaining a consistent flow of HR.</li>
            </ul>
            <br />
          </Typography>
        </Grid>
      </Grid>
      <Box
        role="presentation"
        sx={{
          display: 'block',
          position: 'fixed',
          bottom: 32,
          left: 22,
          zIndex: 1,
          transition: 'transform 0.3s ease-in-out',
          transform: 'scale(1)',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      ></Box>
    </Box>
  )
}
