import Header from "../utils/Header";
import Footer from "../utils/Footer";
import Main from "./Main";
import ImageWithOverlay from "../utils/ImageWithOverlay";

import image from "../../public/banner/02.jpeg";
export default function CompleteAbout() {
  return (
    <>
      <Header />
      <ImageWithOverlay image={image} height="400px" titlePartOne="About" titlePartTwo="Us" />
      <Main />
      <Footer />
    </>
  );
}
