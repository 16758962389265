import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CompleteHome1 from './components/home/CompleteHome1'
import CompleteHome2 from './components/home/CompleteHome2'
import CompleteAbout from './components/about/CompleteAbout'
import Downloadpdf from './components/home/Strategies'
import CompleteContact from './components/contact/CompleteContact'
import Teams from './components/team/Team'
import Strategies from './components/home/Strategies'
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CompleteHome1 />} />
        <Route path="/home2" element={<CompleteHome2 />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/about" element={<CompleteAbout />} />
        <Route path="/contact" element={<CompleteContact />} />
        <Route path="/strategies" element={<Strategies />} />
      </Routes>
    </Router>
  )
}

export default App
