import { Grid, Box, Typography, Button, Fab } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useWindowScrollPositions } from '../hooks/getScrollPositions'
import { useNavigate } from 'react-router-dom'
import Card from '../utils/CustomCards'
import { useParams } from 'react-router-dom'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined'
import SecurityIcon from '@mui/icons-material/Security'
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined'
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined'
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined'
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import useWindowDimensions from '../hooks/getScreenDimensions'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import Title from '../utils/Title'
import WORK from '../../public/image/Untitled design (6).jpg'

export default function Main() {
  const { id } = useParams()
  useEffect(() => {
    console.log(useParams)
  }, [])
  const { width } = useWindowDimensions()
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const { scrollY } = useWindowScrollPositions()
  const navigate = useNavigate()
  const moveToWeatherAdbisoryPage = () => {
    navigate('/weather-advisory')
  }
  return (
    <Box>
      <Title title={'Home'}></Title>
      <Grid container mt="50px">
        {width >= 1200 ? <Grid lg={1} xl={2}></Grid> : null}
        <Grid
          item
          pl={2}
          pr={2}
          xs={12}
          lg={6}
          xl={width < 1800 ? 4 : 3}
          alignItems="flex-end"
          style={{
            display: 'flex',
            justifyContent: width < 1200 ? 'center' : 'center',
            alignItems: 'center',
          }}
        >
          <Card
            mainCardImage={WORK}
            mainCardImageHeight={width < 1200 ? '250px' : '400px'}
            mainCardMaxWidth={'1000px'}
            mainCardBoxShadow={'none'}
            mainCardHeight={width < 1200 ? '250px' : '400px'}
            mainCardMaxHeight={'400px'}
            mainCardBorderRadius={'15px'}
            mainCardWriteOnImage={null}
            basicCard={false}
          />
        </Grid>
        {width < 1200 && width > 800 ? <Grid xs={1}></Grid> : null}
        <Grid
          item
          xs={width <= 800 ? 12 : 9}
          lg={5}
          xl={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginTop: width < 1200 ? '50px' : '0px',
          }}
        >
          <Typography align="start" fontFamily="Poppins" color="rgb(101,77,52)"></Typography>
          <Typography align="start" fontSize={30} mt={2} fontFamily="Poppins" color="black">
            <h4>VISION</h4>
          </Typography>
          <Typography
            align="start"
            fontFamily="Poppins"
            mb={3}
            line-height="10px"
            word-spacing="15px"
            textAlign={'justify'}
          >
            At the heart of our organization lies a commitment to opening the world's door, making
            overseas employment accessible, and achievable for everyone. We envision a future where
            boundaries do not define talent, and opportunities are not limited by geography. Our aim
            is to be the bridge that connects skilled individuals with international careers,
            fostering mutual growth, understanding, and prosperity.
          </Typography>

          <Typography align="start" fontSize={30} mt={2} fontFamily="Poppins" color="black">
            <h4>METHODOLOGY</h4>
          </Typography>
          <Typography
            align="start"
            fontFamily="Poppins"
            mb={3}
            line-height="10px"
            word-spacing="15px"
            textAlign={'justify'}
          >
            In the past multiple committees on the subject have been formed; However, there is a
            need of having a platform for oversight and high power decision making with TORs and
            timeline as follows:
            <ul>
              <li>Oversee from basics to achieve results for Skilled/Unskilled HR Exports.</li>
              <li>Collaboration with Public and private Stakeholders.</li>
            </ul>
          </Typography>

          {/* <Typography
            align="start"
            fontSize={30}
            mb={2}
            fontFamily="Poppins"
            color="black"
          >
            <h4>IMMEDIATE:</h4>
          </Typography>
          <Typography
            align="start"
            fontFamily="Poppins"
            mb={3}
            line-height= "10px"
            word-spacing= "15px"
            color="text.secondary"
            textAlign={"justify"}
          >
          <ul>
              <li>Reorientation of OES ex FF.</li>
              <li>Commencement of multi-field skill trainings with International certification.</li>
            </ul>
            </Typography>*/}
        </Grid>
        {width < 1200 && width > 800 ? <Grid xs={1}></Grid> : null}
        {width >= 1200 ? <Grid lg={1} xl={2}></Grid> : null}
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}></Grid>
      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}>
        <Grid item xs={12} md={width < 1800 ? 10 : 7}>
          <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              borderBottom: '1px solid rgb(101,77,52)',
              marginBottom: '5px',
              paddingBottom: '5px',
            }}
          >
            <h4>CHALLENGES</h4>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography align="start" mt="20px" mb="15px" fontFamily="Poppins" textAlign={'justify'}>
            <ul>
              <li>Lack of integration amongst the institutions.</li>
              <li>Lack of recognized international Accreditation/Certifications.</li>
              <li>Conformity with the Global Labor Market.</li>
              <li>Absence of National Database of Human Resources (Sector-wise).</li>
              <li>Lack of Capacity Building/Training opportunities.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}>
        <Grid item xs={12} md={width < 1800 ? 10 : 7}>
          <Typography
            align="start"
            variant="h5"
            fontFamily="Poppins"
            style={{
              borderBottom: '1px solid rgb(101,77,52)',
              marginBottom: '5px',
              paddingBottom: '5px',
            }}
          >
            <h4>IMPACTS</h4>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography align="start" mt="20px" mb="15px" fontFamily="Poppins" textAlign={'justify'}>
            <ul>
              <li>Employment opportunities.</li>
              <li>Economic growth through remittances.</li>
              <li>Skill development and training.</li>
              <li>Cultural exchange.</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>

      <Box
        role="presentation"
        sx={{
          display: 'block',
          position: 'fixed',
          bottom: 32,
          left: 22,
          zIndex: 1,
          transition: 'transform 0.3s ease-in-out',
          transform: 'scale(1)',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      ></Box>
    </Box>
  )
}
