import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Header from '../utils/Header'
import ImageWithOverlay from '../utils/ImageWithOverlay'
import Footer from '../utils/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import image from '../../public/banner/Untitled design (3).jpg'
import { Grid, Typography } from '@mui/material'
import useWindowDimensions from '../hooks/getScreenDimensions'

const Strategies = () => {
  const [text, setText] = useState('')
  const { width } = useWindowDimensions()

  const handleDownload = () => {
    const blob = new Blob([text], { type: 'text/plain' })
    const downloadLink = document.createElement('a')

    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.download = 'downloaded_text.txt'

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <div>
      <Header />
      <ImageWithOverlay
        image={image}
        height="400px"
        titlePartOne="STRATEGIES & "
        titlePartTwo="INITIATIVES"
      />
      <Container>
        <Grid container justifyContent="center" alignItems="center" mt="10px" p={5}>
          <Grid item xs={12} md={width < 1800 ? 10 : 7}>
            <Typography
              align="start"
              variant="h5"
              fontFamily="Poppins"
              style={{
                borderBottom: '1px solid rgb(101,77,52)',
                marginBottom: '5px',
                paddingBottom: '5px',
              }}
            >
              <strong>STRATEGIES & INITIATIVES</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={width < 1800 ? 10 : 7}>
            <Typography
              align="start"
              mt="20px"
              mb="15px"
              fontFamily="Poppins"
              textAlign={'justify'}
            >
              <ul>
                <li>
                  <strong>Skill Development and Training</strong>
                  <p>
                    Enhance Vocational Training programs to align the demand of Key Sectors in the
                    International Market.
                  </p>
                </li>
                <li>
                  <strong>Recognition of qualification</strong>
                  <p>
                    Work towards international accreditation to ensure compatibility with Global
                    Standards.
                  </p>
                </li>
                <li>
                  <strong>Facilitation</strong>
                  <p>Streamline the Visa Application Process.</p>
                </li>
                <li>
                  <strong>Complaint Response Mechanism (CRM)</strong>
                  <p>
                    Establish a mechanism for effective complaint management and legal assistance.
                  </p>
                </li>
                <li>
                  <strong>International Collaboration</strong>
                  <p>Negotiate Bilateral Agreements to promote fair recruitment practices.</p>
                </li>
                <li>
                  <strong>Partnerships</strong>
                  <p>
                    Foster partnerships with International Organizations, Foreign Governments, and
                    Private sector.{' '}
                  </p>
                </li>
                <li>
                  <strong>Job Portal</strong>
                  <p>Accommodate Pakistan's overseas job seekers through digital Facilitation.</p>
                </li>

                <li>
                  <strong>Monitoring and Evaluation</strong>
                  <p>
                    Establish a dedicated Monitoring and Evaluation Framework to track the progress
                    and effectiveness of initiatives.
                  </p>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  )
}

export default Strategies
