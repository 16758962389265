import React from 'react';
import { Helmet } from "react-helmet";

function Title({ title }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>OSE | OverSeas Pakistan</title>
      <link rel="icon" href="/path/to/your/favicon.ico" />
    </Helmet>
  );
}

export default Title;
